import { configureStore } from "@reduxjs/toolkit";
import changeState from "./features/changeState/changeState";
import senzoriSlice from "./features/senzoriSlice";
import sanatateSlice from "./features/sanatateSlice";
import userSlice from "./features/userSlice";

const store = configureStore({
  reducer: {
    sidebarShow: changeState,
    senzori: senzoriSlice.reducer,
    sanatate: sanatateSlice.reducer,
    user: userSlice,
  },
});

export default store;
