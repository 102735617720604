import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSenzori = createAsyncThunk(
  "senzori/fetchSenzori",
  async (loc, thunkAPI) => {
    try {
      const params = {
        loc,
      };
      const response = await axios.get("/dashboard", { params });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const senzoriSlice = createSlice({
  name: "senzori",
  initialState: {
    senzoriData: [],
    loading: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchSenzori.fulfilled]: (state, action) => {
      state.senzoriData = action.payload;
    },
    [fetchSenzori.rejected]: (state, action) => {
      state.senzoriData = action.payload;
    },
  },
});

export const selectSenzori = (state) => state.senzoriData;

export default senzoriSlice;
