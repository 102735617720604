import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSanatate = createAsyncThunk(
  "sanatate/fetchSanatate",
  async (locatie, thunkAPI) => {
    try {
      const params = {
        locatie,
      };
      const response = await axios.get("/tratament", { params });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sanatateSlice = createSlice({
  name: "sanatate",
  initialState: {
    sanatateData: [],
    loading: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchSanatate.fulfilled]: (state, action) => {
      state.sanatateData = action.payload;
    },
    [fetchSanatate.rejected]: (state, action) => {
      state.sanatateData = action.payload;
    },
  },
});

export const selectSanatate = (state) => state.sanatateData;

export default sanatateSlice;
