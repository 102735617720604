import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";

import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

React.icons = icons;

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <Auth0ProviderWithHistory
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
      >
        <App />
      </Auth0ProviderWithHistory>
    </Provider>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
