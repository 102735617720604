import { createSlice } from "@reduxjs/toolkit";

export const changeStateSlice = createSlice({
  name: "set",
  initialState: {
    sidebarShow: "responsive",
  },
  reducers: {
    changeState: (state, action) => {
      state.sidebarShow = action.payload;
    },
  },
});

export const { changeState } = changeStateSlice.actions;

export default changeStateSlice.reducer;
